if (document.getElementsByClassName('contact_jobs-wrapper').length) {
    $(function() {

        // We can attach the `fileselect` event to all file inputs on the page
        $(document).on('change', ':file', function() {
            var input = $(this),
                numFiles = input.get(0).files ? input.get(0).files.length : 1,
                label = input.val().replace(/\\/g, '/').replace(/.*\//, '');
            input.trigger('fileselect', [numFiles, label]);
        });

        // We can watch for our custom `fileselect` event like this
        $(document).ready(function() {
            $(':file').on('fileselect', function(event, numFiles, label) {

                var input = $(this).parents('.input-group').find(':text'),
                    log = numFiles > 1 ? numFiles + ' files selected' : label;

                if (input.length) {
                    input.val(log);
                } else {
                    if (log) alert(log);
                }

            });
        });

    });
}

$("#boton_enviarJobs")
    .on('click', function() {

        var form = $("#formContactoJobs");

        //Serializing all For Input Values (not files!) in an Array Collection so that we can iterate this collection later.
        var params = form.serializeArray();

        var files = $("#CvJobs")[0].files;

        //Declaring new Form Data Instance  
        var formData = new FormData();

        //Looping through uploaded files collection in case there is a Multi File Upload. This also works for single i.e simply remove MULTIPLE attribute from file control in HTML.  
        formData.append('Cv', files[0]);

        $(params).each(function(index, element) {
            formData.append(element.name, element.value);
        });

        var nombrecomp = $('#CapaNombre input').val();
        var apecomp = $('#CapaApellido input').val();
        var emailcomp = $('#CapaEmail input').val();
        var telcomp = $('#CapaTelefono input').val();
        var cvcomp = $('#CapacV input').val();
        var checkcomp = $('#aceptoJobs:checked').length;

        //console.log(nombrecomp + ", " + apecomp + ", " + emailcomp + "," + telcomp + ", " + checkcomp + ", " +  cvcomp + "," + cvdoscomp + "," + cvtrescomp);

        if (nombrecomp != "" && apecomp != "" && emailcomp != "" && telcomp != "" && checkcomp != 0) {
            if (/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(emailcomp)) {
                if (/^([0-9])*$/i.test(telcomp)) {
                    $.ajax({
                        url: '/child/assets/php/main.php',
                        type: 'POST',
                        dataType: "json",
                        contentType: false,
                        data: formData,
                        cache: false,
                        processData: false,
                        success: function(data) {
                            if (data.respuesta == 1) {
                                mensajeCorrecto();
                            }
                            if (data.respuesta == 0) {
                                mensajeError();
                                $(".formJobs p").html('Error');
                            }
                            if (data.respuesta == 3) {
                                mensajeError();
                                $(".formJobs p").html('Error');
                            }
                        },
                        error: function(err) {
                            //   alert("Error");
                        }
                    });
                } else {
                    mensajeError();
                    $(".formJobs p").html('Error');
                }
            } else {
                mensajeError();
                $(".formJobs p").html('Error');
            }
        } else {
            mensajeError();
            $(".formJobs p").html('Error');
        }
    });

function mensajeError() {
    $('.formJobs').css("display", "block");
    $('.form-control').css("box-shadow", "none");
    $('.formJobs p').addClass("danger");
    setTimeout(function() {
        $('.formJobs').css("display", "none");
        $('.formJobs p').removeClass("danger");
    }, 6000);
}

function mensajeCorrecto() {
    $('.formJobs').css("display", "block");
    $('.form-control').css("box-shadow", "none");
    $('.formJobs p').addClass("success");
    $(".formJobs p").html('E-mail sent');
    $(".form-control").val('');

    setTimeout(function() {
        $('.formJobs').css("display", "none");
        $('.formJobs p').removeClass("success");
    }, 6000);
}